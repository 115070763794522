import validate from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.11.1_typescript@5.6.2_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.11.1_typescript@5.6.2_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/opt/buildhome/repo/middleware/auth.ts"),
  log: () => import("/opt/buildhome/repo/middleware/log.ts"),
  ptxn: () => import("/opt/buildhome/repo/middleware/ptxn.ts")
}