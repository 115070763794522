import payload_plugin_nGi82b0ah1 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-vuefire@1.0.4_@firebase+app-types@0.9.2_firebase-admin@12.6.0_firebase@10.14.0_vuefire@3.2.0/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.js";
import revive_payload_client_9KJu8BSOIz from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.11.1_typescript@5.6.2_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_YBaLDTLcAg from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.11.1_typescript@5.6.2_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_eVsZwzXpII from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.11.1_typescript@5.6.2_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_yuVpx2BuF1 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@2.2.18_vite@5.4.8_vue@3.5.11/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_vWGozOFuDw from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.11.1_typescript@5.6.2_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_ZbGqcfZeRr from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.11.1_typescript@5.6.2_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_blWwbNAKPG from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.11.1_typescript@5.6.2_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_X5v65C96ac from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.11.1_typescript@5.6.2_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _64nuxt_scripts_D34jXE4xNh from "/opt/buildhome/repo/.nuxt/modules/@nuxt-scripts.mjs";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_v6G0o14Le2 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.11.1_typescript@5.6.2_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_zZILi1PFfb from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-vuefire@1.0.4_@firebase+app-types@0.9.2_firebase-admin@12.6.0_firebase@10.14.0_vuefire@3.2.0/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.js";
import vuefire_auth_plugin_client_cCwkwh1j5u from "/opt/buildhome/repo/.nuxt/vuefire-auth-plugin.client.mjs";
import vuefire_plugin_8cq4FnzXKb from "/opt/buildhome/repo/.nuxt/vuefire-plugin.mjs";
import titles_6cTYB9QTlO from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.19_h3@1.13.0_vite@5.4.8_vue@3.5.11/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import siteConfig_cDQII6xqQD from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_vite@5.4.8_vue@3.5.11/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_uq187vPOeN from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_vite@5.4.8_vue@3.5.11/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import slideovers_J3lTlazylS from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.6_vite@5.4.8_vue@3.5.11/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_Gt9lJBsJi3 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.6_vite@5.4.8_vue@3.5.11/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_U7zLVPBgb6 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.6_vite@5.4.8_vue@3.5.11/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_1rze2VsG7c from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_tLvy0j95DY from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.5.2_vite@5.4.8_vue@3.5.11/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollbars_client_00TO0odwi6 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.4.3_vite@5.4.8_vue@3.5.11/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import presets_QvOK6CALx7 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.4.3_vite@5.4.8_vue@3.5.11/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_gbKpdJMtox from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.4.3_vite@5.4.8_vue@3.5.11/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import paddleJS_client_6OSkSWlVfm from "/opt/buildhome/repo/plugins/paddleJS.client.ts";
import sentry_client_shVUlIjFLk from "/opt/buildhome/repo/plugins/sentry.client.ts";
import defaults_JA1EUmzDci from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.19_h3@1.13.0_vite@5.4.8_vue@3.5.11/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
export default [
  payload_plugin_nGi82b0ah1,
  revive_payload_client_9KJu8BSOIz,
  unhead_YBaLDTLcAg,
  router_eVsZwzXpII,
  _0_siteConfig_yuVpx2BuF1,
  payload_client_vWGozOFuDw,
  navigation_repaint_client_ZbGqcfZeRr,
  check_outdated_build_client_blWwbNAKPG,
  chunk_reload_client_X5v65C96ac,
  _64nuxt_scripts_D34jXE4xNh,
  components_plugin_KR1HBZs4kY,
  prefetch_client_v6G0o14Le2,
  plugin_client_zZILi1PFfb,
  vuefire_auth_plugin_client_cCwkwh1j5u,
  vuefire_plugin_8cq4FnzXKb,
  titles_6cTYB9QTlO,
  siteConfig_cDQII6xqQD,
  inferSeoMetaPlugin_uq187vPOeN,
  slideovers_J3lTlazylS,
  modals_Gt9lJBsJi3,
  colors_U7zLVPBgb6,
  plugin_client_1rze2VsG7c,
  plugin_tLvy0j95DY,
  scrollbars_client_00TO0odwi6,
  presets_QvOK6CALx7,
  variables_gbKpdJMtox,
  paddleJS_client_6OSkSWlVfm,
  sentry_client_shVUlIjFLk,
  defaults_JA1EUmzDci
]